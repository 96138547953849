export default {
  red: {
    '500': '#EB4C80',
  },
  green: {
    '500': '#3A8443',
  },
  purple: {
    '50': '#F1EBF9',
    '100': '#D8C8EF',
    '200': '#BFA4E5',
    '300': '#A580DB',
    '400': '#8C5CD0',
    '500': '#572B96',
    '600': '#5C2D9F',
    '700': '#452277',
    '800': '#2E174F',
    '900': '#170B28',
  },
  blackAlpha: {
    100: '#212121',
    80: '#212121CC',
    60: '#21212160',
    30: '#2121214D',
    10: '#2121211A',
  },
  brand: {
    purple: '#572B96',
    white: '#F8F8F9',
    green: '#3A8443',
    orange: '#D48527',
    red: '#C34D4D',
    yellow: '#BEA523',
  },
};
