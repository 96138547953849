import { Flex } from '@chakra-ui/react';

export const DottedSpacer = (props) => {
  return (
    <Flex
      sx={{
        flex: 1,
        _after: {
          content: '""',
          borderBottom: '2px dotted',
          flex: 1,
          margin: '7px .5em',
          borderColor: '#2121213D',
        },
      }}
      {...props}
    />
  );
};
