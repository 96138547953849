import { format, parseISO } from 'date-fns';

import { useAuth } from '@/context/AuthContext';
import {
  HorseDto,
  FileDto,
  AssociatedDocumentFileType,
  TransactionUserProfile,
  HorseListing,
  ListingStatus,
  TransactionEventType,
} from '@/generated/core.graphql';

export const getProfileFullName = (profile: TransactionUserProfile) => {
  if (!profile) return '';

  return `${profile.firstName} ${profile.lastName}`;
};

export const formatCurrency = (value: number, precision: number = 2) => {
  // Convert to number and format as currency
  const numberValue = Number(value);
  if (!isNaN(numberValue)) {
    return `£${numberValue.toLocaleString('en-GB', {
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
    })}`;
  }
  return '';
};

export const formatTime = (timeStr: string): string => {
  if (!timeStr) {
    return '';
  }

  const startTime = parseISO(timeStr);
  return `${format(startTime, 'dd MMM yyyy')} ${format(startTime, 'HH:mm')}`;
};

export const calculateListingTotalPrice = (
  listingPrice: number,
  platformFeePercentage: number,
  vatPercentage: number
) => {
  const platformFee = (listingPrice * platformFeePercentage) / 100;
  const vat = (platformFee * vatPercentage) / 100;
  return listingPrice + platformFee + vat;
};

export const calculateHorseTransparencyScore = (
  horse: Partial<HorseDto>,
  horseDocuments: FileDto[],
  noBreedingCertificate = false,
  noVeterinarianHistory = false,
  noCompetitionRecords = false
) => {
  let score = 0;
  const countedDocumentTypes = new Set<AssociatedDocumentFileType>();
  const relevantDocumentTypes = [
    AssociatedDocumentFileType.BreedingCertificate,
    AssociatedDocumentFileType.VaccinationRecord,
    AssociatedDocumentFileType.VetHistory,
    AssociatedDocumentFileType.CompetitionRecord,
  ].filter((type) => {
    if (type === AssociatedDocumentFileType.BreedingCertificate)
      return !noBreedingCertificate;
    if (type === AssociatedDocumentFileType.VetHistory)
      return !noVeterinarianHistory;
    if (type === AssociatedDocumentFileType.CompetitionRecord)
      return !noCompetitionRecords;

    return true;
  });

  if (horse?.passportNumber) score += 10;
  if (horse?.transponderCode) score += 10;
  if (horse?.dam) score += 10;
  if (horse?.sire) score += 10;
  if (horse?.damSire) score += 10;

  if (noBreedingCertificate) score += 10;
  if (noVeterinarianHistory) score += 20;
  if (noCompetitionRecords) score += 10;

  horseDocuments?.forEach((file) => {
    if (
      !countedDocumentTypes.has(file.associatedDocumentFileType) &&
      relevantDocumentTypes.includes(file.associatedDocumentFileType)
    ) {
      score +=
        file.associatedDocumentFileType ===
        AssociatedDocumentFileType.VetHistory
          ? 20
          : 10;
      countedDocumentTypes.add(file.associatedDocumentFileType);
    }
  });

  return score;
};

export const checkDisallowedEvent = (
  event: TransactionEventType,
  listing: HorseListing
) => {
  const { self } = useAuth();
  const isBuyer = listing?.sellerId !== self?.id;

  if (
    listing?.status === ListingStatus.Reserved &&
    isBuyer &&
    listing?.reservedUserId !== self?.id &&
    !allowedEventTypeWhenReserved.includes(event)
  ) {
    return true;
  }

  return false;
};

export const allowedEventTypeWhenReserved = [
  TransactionEventType.RequestDocumentViewing,
  TransactionEventType.AcceptDocumentViewing,
  TransactionEventType.RejectDocumentViewing,

  TransactionEventType.RequestPrice,
  TransactionEventType.AcceptPriceRequest,
  TransactionEventType.RejectPriceRequest,
];

export const categoryOptions = [
  { label: 'Horse - All rounder', id: 'Horse - All rounder' },
  { label: 'Horse - Arab', id: 'Horse - Arab' },
  { label: 'Horse - Broodmare', id: 'Horse - Broodmare' },
  {
    label: 'Horse - Cobs and Heavy horses',
    id: 'Horse - Cobs and Heavy horses',
  },
  { label: 'Horse - Companions', id: 'Horse - Companions' },
  { label: 'Horse - Dressage', id: 'Horse - Dressage' },
  { label: 'Horse - Driving', id: 'Horse - Driving' },
  { label: 'Horse - Endurance', id: 'Horse - Endurance' },
  { label: 'Horse - Event horse', id: 'Horse - Event horse' },
  { label: 'Horse - Ex Racehorse', id: 'Horse - Ex Racehorse' },
  { label: 'Horse - Foal', id: 'Horse - Foal' },
  { label: 'Horse - Foreign breed', id: 'Horse - Foreign breed' },
  { label: 'Horse - Happy hackers', id: 'Horse - Happy hackers' },
  {
    label: 'Horse - Hunter and Team Chase',
    id: 'Horse - Hunter and Team Chase',
  },
  { label: 'Horse - Iberian', id: 'Horse - Iberian' },
  { label: 'Horse - Polo', id: 'Horse - Polo' },
  { label: 'Horse - Racehorse', id: 'Horse - Racehorse' },
  { label: 'Horse - Schoolmaster', id: 'Horse - Schoolmaster' },
  { label: 'Horse - Show horse', id: 'Horse - Show horse' },
  { label: 'Horse - Show jumper', id: 'Horse - Show jumper' },
  { label: 'Horse - Sport horse', id: 'Horse - Sport horse' },
  { label: 'Horse - Stallion', id: 'Horse - Stallion' },
  { label: 'Pony - Arab', id: 'Pony - Arab' },
  { label: 'Pony - Broodmare', id: 'Pony - Broodmare' },
  { label: 'Pony - Cobs', id: 'Pony - Cobs' },
  { label: 'Pony - Companion', id: 'Pony - Companion' },
  { label: 'Pony - Coloured Ponies', id: 'Pony - Coloured Ponies' },
  { label: 'Pony - Competition Pony', id: 'Pony - Competition Pony' },
  { label: 'Pony - Dressage Pony', id: 'Pony - Dressage Pony' },
  { label: 'Pony - Event Pony', id: 'Pony - Event Pony' },
  { label: 'Pony - First Pony', id: 'Pony - First Pony' },
  { label: 'Pony - Games Pony', id: 'Pony - Games Pony' },
  { label: 'Pony - Happy Hacker', id: 'Pony - Happy Hacker' },
  { label: 'Pony - Hunter Pony And Whp', id: 'Pony - Hunter Pony And Whp' },
  { label: 'Pony - Hunting Ponies', id: 'Pony - Hunting Ponies' },
  {
    label: 'Pony - Lead Rein And First Ridden',
    id: 'Pony - Lead Rein And First Ridden',
  },
  { label: 'Pony - Miniatures', id: 'Pony - Miniatures' },
  { label: 'Pony - Polo Pony', id: 'Pony - Polo Pony' },
  { label: 'Pony - Pony Club Pony', id: 'Pony - Pony Club Pony' },
  { label: 'Pony - Racing Pony', id: 'Pony - Racing Pony' },
  { label: 'Pony - Schoolmaster', id: 'Pony - Schoolmaster' },
  { label: 'Pony - Show Jumping Pony', id: 'Pony - Show Jumping Pony' },
  { label: 'Pony - Show Pony', id: 'Pony - Show Pony' },
  { label: 'Pony - Stallion', id: 'Pony - Stallion' },
];
