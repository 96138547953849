import { useEffect, useState } from 'react';

import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';

export class DropDownOption {
  id: string;
  label: string;

  constructor(id: string, label: string) {
    this.id = id;
    this.label = label;
  }
}

interface DropDownProps {
  isMandatory: boolean;
  options: DropDownOption[];
  title: string;
  selectedOptionId?: string;
  onSelect: (option: DropDownOption) => void;
  config?: Config;
  dataCy?: string;
}

interface Config {
  customStyling?: Record<string, string>;
  placeHolderText?: string;
}

const DropDown: React.FC<DropDownProps> = ({
  isMandatory,
  options,
  title,
  selectedOptionId,
  onSelect,
  config,
  dataCy,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalBodyRef, setModalBodyRef] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isOpen && modalBodyRef) {
      modalBodyRef.scrollTop = 0;
    }
  }, [isOpen, modalBodyRef]);

  // Modified to include a "Clear" option if not mandatory
  const extendedOptions = isMandatory
    ? options
    : [...options, { id: '', label: 'Clear Selection' }];

  const handleSelect = (option: DropDownOption) => {
    onSelect(option);
    setIsOpen(false);
  };

  const placeholderText = config?.placeHolderText || 'Select';

  return (
    <>
      <InputGroup>
        <Input
          borderColor='blackAlpha.30'
          cursor={'pointer'}
          type='text'
          value={selectedOptionId ? selectedOptionId : placeholderText}
          readOnly
          onClick={() => setIsOpen(true)}
          onFocus={(e) => e.target.blur()}
          borderRadius={'50px'}
          color={selectedOptionId ? 'blackAlpha.100' : 'blackAlpha.30'}
          sx={{ ...config?.customStyling }}
          data-cy={dataCy}
        />
        <InputRightElement
          children={<ChevronDownIcon color='blackAlpha.30' />}
          pointerEvents='none'
          marginRight='1rem'
        />
      </InputGroup>
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent
          marginTop='auto'
          marginBottom='0'
          marginLeft='auto'
          marginRight='auto'
          width='100vw'
          maxWidth='100vw'
          bottom='0'
          position='fixed'
          height='auto'
        >
          <ModalBody
            padding={0}
            maxHeight={'90vh'}
            overflowY={'auto'}
            ref={(el) => {
              if (el) setModalBodyRef(el);
            }}
          >
            <Stack spacing={4} p={3}>
              <Center mb={4}>{title}</Center>
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {extendedOptions.map((option, index) => (
                  <li key={index}>
                    <Box
                      onClick={() => handleSelect(option)}
                      cursor={'pointer'}
                      display={'flex'}
                      justifyContent={'space-between'}
                      width={'100%'}
                      alignItems={'center'}
                      textAlign={'left'}
                      borderBottom={'1px solid #2121211A'}
                      py='md'
                    >
                      <Text flex={1} textAlign={'left'}>
                        {option.label}
                      </Text>
                      <ChevronRightIcon color='blackAlpha.30' />
                    </Box>
                  </li>
                ))}
              </ul>
              <Button onClick={() => setIsOpen(false)}>Back</Button>
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DropDown;
