import alertTheme from './alert';
import { avatarTheme } from './avatar';
import buttonTheme from './button';
import { cardTheme } from './card';
import checkboxTheme from './checkbox';
import formError from './formError';
import formLabelTheme from './formLabel';
import inputTheme from './input';
import linkTheme from './link';
import { modalTheme } from './modal';
import radioTheme from './radio';
import { selectTheme } from './select';
import sliderTheme from './slider';
import { switchTheme } from './switch';
import tabsTheme from './tabs';
import textTheme from './text';
import { textareaTheme } from './textarea';

export default {
  Input: inputTheme,
  Button: buttonTheme,
  Link: linkTheme,
  Card: cardTheme,
  Radio: radioTheme,
  FormError: formError,
  Checkbox: checkboxTheme,
  Text: textTheme,
  FormLabel: formLabelTheme,
  Avatar: avatarTheme,
  Tabs: tabsTheme,
  Textarea: textareaTheme,
  Slider: sliderTheme,
  Alert: alertTheme,
  Switch: switchTheme,
  Select: selectTheme,
  Modal: modalTheme,
};
