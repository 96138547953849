/* eslint-disable import/prefer-default-export */
import { FC, ReactNode, useCallback } from 'react';

import {
  Button,
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  Flex,
  Text,
  useBoolean,
} from '@chakra-ui/react';
// eslint-disable-next-line import/no-named-default
import { default as NextLink, LinkProps as NextLinkProps } from 'next/link';
import { FormattedMessage } from 'react-intl';

import CheckIcon from '@/components/Icons/CheckIcon';
import ShareIcon from '@/components/Icons/ShareIcon';
import { TrackingParams, useTracking } from '@/context/TrackingContext';

export type LinkProps = {
  children: JSX.Element | ReactNode | string;
} & ChakraLinkProps &
  NextLinkProps & {
    tracking?: TrackingParams;
  };

export const Link: FC<LinkProps> = ({
  tracking,
  onClick,
  ...restProps
}: LinkProps) => {
  const { trackEvent } = useTracking();
  const handleClick = useCallback(
    (e) => {
      if (tracking) {
        trackEvent(tracking);
      }
      if (onClick) {
        onClick(e);
      }
    },
    [onClick, trackEvent, tracking]
  );

  return <ChakraLink as={NextLink} onClick={handleClick} {...restProps} />;
};

const ShareableLink = ({ title, text, url }) => {
  const [copySuccess, setCopySuccess] = useBoolean(false);
  // const [copySuccess, setCopySuccess] = useState('');

  const copyToClipboard = () => {
    if (typeof window !== 'undefined') {
      if (navigator.canShare) {
        navigator.share({
          title,
          text,
          url,
        });
      } else {
        navigator.clipboard.writeText(url).then(
          () => {
            setCopySuccess.on();
            setTimeout(() => setCopySuccess.off(), 1200);
          },
          () => {
            setCopySuccess.off();
          }
        );
      }
    }
  };

  return (
    <>
      {copySuccess && <>{copySuccess}</>}
      <Button
        as={Flex}
        onClick={copyToClipboard}
        alignItems='center'
        gap='12px'
        data-cy='share-profile-button'
      >
        {copySuccess ? (
          <>
            <CheckIcon />
            <Text variant='labelBold'>
              <FormattedMessage
                id='views.horse.listing.profile.linkCopied'
                defaultMessage='Link copied'
              />
            </Text>
          </>
        ) : (
          <>
            <ShareIcon />
            <Text variant='labelBold'>
              <FormattedMessage
                id='views.horse.listing.profile.shareProfile'
                defaultMessage='Share profile'
              />
            </Text>
          </>
        )}
      </Button>
    </>
  );
};

export default ShareableLink;
