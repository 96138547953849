import { FC, ReactNode, useCallback } from 'react';

import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
} from '@chakra-ui/react';
import { useIntl } from 'react-intl';

import { TrackingParams, useTracking } from '@/context/TrackingContext';
import {
  TransactionEventType,
  TransactionRecord,
} from '@/generated/core.graphql';
import { checkDisallowedEvent } from '@/utils/helpers';

interface CTAProps {
  transactionRecord: TransactionRecord;
  transactionEvent: TransactionEventType;
}

export type ButtonProps = {
  children: JSX.Element | ReactNode | string;
} & ChakraButtonProps & {
    tracking?: TrackingParams;
    ctaProps?: CTAProps;
  };

export const Button: FC<ButtonProps> = ({
  tracking,
  ctaProps,
  onClick,
  ...props
}: ButtonProps) => {
  const { trackEvent } = useTracking();
  const { formatMessage } = useIntl();

  const handleClick = useCallback(
    (e) => {
      if (tracking) {
        trackEvent(tracking);
      }
      if (onClick) {
        onClick(e);
      }
    },
    [onClick, trackEvent, tracking]
  );

  let isDisallowed = false;
  let buttonTooltip = props.title;

  if (ctaProps) {
    isDisallowed = checkDisallowedEvent(
      ctaProps.transactionEvent,
      ctaProps.transactionRecord?.listing
    );
  }

  if (isDisallowed) {
    buttonTooltip = formatMessage({
      id: 'views.transaction.horseReservedStep.button.title',
      defaultMessage: 'This action is not allowed on reserved listings.',
    });
  }

  const isDisabled = ctaProps
    ? props.isDisabled || isDisallowed
    : props.isDisabled;

  return (
    <ChakraButton
      onClick={handleClick}
      {...props}
      title={buttonTooltip}
      isDisabled={isDisabled}
    />
  );
};
