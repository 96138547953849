import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  // define the part you're going to style
  borderRadius: '50px',
  width: { base: '100%', md: 'auto' },
});

const solid = defineStyle(() => ({
  height: '48px',
  fontSize: '16px',
  lineHeight: '20px',
}));

const outline = defineStyle(() => ({
  height: '48px',
  fontSize: '16px',
  lineHeight: '20px',
  borderColor: 'blackAlpha.30',
  color: 'blackAlpha.100',
}));

const icon = defineStyle((props) => ({
  ...props.theme.components.Button.variants.ghost(props),
  boxSize: '40px',
  borderRadius: 0,
  backgroundColor: 'transparent',
}));

const outlinedIcon = defineStyle((props) => ({
  ...props.theme.components.Button.variants.ghost(props),
  boxSize: '40px',
  borderRadius: 0,
  backgroundColor: 'transparent',
  borderWidth: '1px',
  borderColor: 'blackAlpha.10',
  color: 'blackAlpha.80',
}));

const blank = defineStyle(() => ({
  height: '48px',
  fontSize: '16px',
  lineHeight: '20px',
  borderColor: 'none',
  color: 'blackAlpha.100',
}));

const buttonTheme = defineStyleConfig({
  defaultProps: {
    colorScheme: 'purple',
  },
  baseStyle,
  variants: {
    icon,
    outlinedIcon,
    solid,
    outline,
    blank,
  },
});

export default buttonTheme;
