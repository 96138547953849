/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-underscore-dangle */
import * as React from 'react';
import { FC, useCallback, useEffect } from 'react';

import {
  Identify,
  identify,
  init,
  reset,
  setUserId as ampSetUserId,
  track,
  add,
} from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

import Config from '@/config';
import { TrackingEvent } from '@/enums/tracking';

type UserProperties = {
  userId?: number;
  isTestUser?: boolean;
};

interface BaseAmplitudeData {
  horseId: number;
  listingId: number;
}

interface HorseProfileCompleteAmplitudeData {
  horseTransparencyScore: number;
  horseId: number;
}

interface DeleteHorseProfileAmplitudeData {
  horseId: number;
}

interface TransportationOptionAmplitudeData extends BaseAmplitudeData {
  transportationMethod: string;
}

interface CreateHorseListingAmplitudeData {
  horseId: number;
}

interface CompleteProfileCreation {
  formFieldsFilled: string;
}

interface SelectUserType {
  userType: string;
}

interface TutorialData {
  tutorialId: string;
  timestamp: number;
  userId: number;
  slideNumber?: number;
}

export type TrackingParams =
  | {
      event: TrackingEvent.ACCOUNT_CREATED;
    }
  | {
      event: TrackingEvent.START_HORSE_PROFILE_CREATION;
    }
  | {
      event: TrackingEvent.COMPLETE_PROFILE_CREATION;
      amplitudeData: CompleteProfileCreation;
    }
  | {
      event: TrackingEvent.SELECT_USER_TYPE;
      amplitudeData: SelectUserType;
    }
  | {
      event: TrackingEvent.COMPLETE_HORSE_PROFILE;
      amplitudeData: HorseProfileCompleteAmplitudeData;
    }
  | {
      event: TrackingEvent.DELETE_HORSE_PROFILE;
      amplitudeData: DeleteHorseProfileAmplitudeData;
    }
  | {
      event: TrackingEvent.CREATE_HORSE_LISTING;
      amplitudeData: CreateHorseListingAmplitudeData;
    }
  | {
      event: TrackingEvent.COMPLETE_HORSE_LISTING;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.VIEW_HORSE_LISTING;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.REQUEST_DOCUMENT_ACCESS;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.ACCEPT_DOCUMENT_ACCESS;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.REJECT_DOCUMENT_ACCESS;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.REQUEST_HORSE_VIEWING;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.ACCEPT_HORSE_VIEWING;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.REJECT_HORSE_VIEWING;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.REQUEST_HORSE_VETTING;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.ACCEPT_HORSE_VETTING;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.REJECT_HORSE_VETTING;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.ACCEPT_HORSE_VETTING_WITH_DEPOSIT;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.REQUEST_DEPOSIT;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.ACCEPT_DEPOSIT_REQUEST;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.REJECT_DEPOSIT_REQUEST;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.REQUEST_HORSE_PURCHASE;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.ACCEPT_HORSE_PURCHASE;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.REJECT_HORSE_PURCHASE;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.SELECT_TRANSPORTATION_OPTION;
      amplitudeData: TransportationOptionAmplitudeData;
    }
  | {
      event: TrackingEvent.HORSE_LOADING_VIDEO_UPLOADED;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.HORSE_DELIVERY_CONFIRMED;
      amplitudeData: BaseAmplitudeData;
    }
  | {
      event: TrackingEvent.BUYER_CLICKS_INVITE_SELLER_CTA;
    }
  | {
      event: TrackingEvent.TUTORIAL_STARTED;
      amplitudeData: TutorialData;
    }
  | {
      event: TrackingEvent.TUTORIAL_COMPLETED;
      amplitudeData: TutorialData;
    }
  | {
      event: TrackingEvent.TUTORIAL_SKIPPED;
      amplitudeData: TutorialData;
    };

type TrackingContext = {
  trackEvent: (config: TrackingParams) => Promise<void>;
  setUserProperties: (properties: UserProperties) => void;
  setUserId: (userId: number) => void;
  ampReset: () => void;
};

let isAmplitudeEnabled = false;

// eslint-disable-next-line no-redeclare
const TrackingContext = React.createContext<TrackingContext>({
  trackEvent: () => Promise.resolve(),
  setUserProperties: () => undefined,
  setUserId: () => undefined,
  ampReset: () => undefined,
});

const TrackingProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  useEffect(() => {
    init(Config.amplitudeApiKey, null, { minIdLength: 1 });
    isAmplitudeEnabled = true;

    if (isAmplitudeEnabled) {
      const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 1 });

      add(sessionReplayTracking);
    }
  }, []);

  const trackEvent = useCallback(async (options: TrackingParams) => {
    if (isAmplitudeEnabled) {
      track(options.event, (options as any).amplitudeData);
    }

    // GTM
    if (window.dataLayer) {
      window.dataLayer.push({ event: options.event });
    }
  }, []);

  const setUserProperties = useCallback((params: UserProperties) => {
    if (isAmplitudeEnabled) {
      const identity = new Identify();

      Object.keys(params).forEach((key) => {
        if (params[key] !== null && params[key] !== undefined) {
          identity.set(key, params[key]);
        }
      });

      identify(identity);
    }
  }, []);

  const setUserId = useCallback((userId: any) => {
    if (isAmplitudeEnabled) {
      ampSetUserId(String(userId));
    }
  }, []);

  const ampReset = () => {
    if (isAmplitudeEnabled) {
      reset();
    }
  };

  return (
    <TrackingContext.Provider
      value={{
        trackEvent,
        setUserProperties,
        setUserId,
        ampReset,
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
};

function useTracking(): TrackingContext {
  const context = React.useContext(TrackingContext);
  if (context === undefined) {
    throw new Error('useTracking must be used within a TrackingProvider');
  }
  return context;
}

export { TrackingProvider, useTracking };
